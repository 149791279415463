import React, { useState, useRef } from 'react';
import { animated, useSpring, useTrail, useChain } from 'react-spring';
import { Waypoint } from 'react-waypoint';

const items = ['I', 'D', 'E', 'A', 'L'];
const moreitems = [
    'NCOME – Earn Steady, Monthly Rental Income',
    'EPRECIATION – Shelter Your Income With Depreciation Tax Write Offs',
    'QUITY – Pay Down Your Principal Over Time To Grow Equity',
    'PPRECIATION – Benefit From Inflation With Appreciating Real Estate',
    'EVERAGE – Utilize Fixed Rate, Favorably Priced Debt To Increase Your Returns Over The Long Term',
];

const IdealDef = () => {
    const [on, toggle] = useState(false);

    const firstLetters = useRef();
    const trail = useTrail(items.length, {
        ref: firstLetters,
        from: { opacity: 0, transform: 'translate3d(0,15px,0)' },
        to: {
            opacity: on ? 1 : 0,
            transform: on ? 'translate3d(0,0,0px)' : 'translate3d(0,15px,0)',
        },
        config: { friction: 25 },
    });

    const restOfWord = useRef();
    const springz = useSpring({
        ref: restOfWord,
        from: { opacity: 0 },
        to: {
            opacity: on ? 1 : 0,
        },
        config: { tension: 100 },
    });

    useChain(on ? [firstLetters, restOfWord] : [restOfWord, firstLetters]);

    return (
        <div className="ideal-definition__defined">
            <Waypoint
                bottomOffset="10%"
                onEnter={() => {
                    if (!on) toggle(true);
                }}
            />
            {trail.map(({ ...animation }, index) => (
                <h4 className="heading4--bold" style={{ marginBottom: '24px' }}>
                    <animated.span
                        style={{ ...animation }}
                        key={index}
                        className="ideal-definition__first-letter"
                    >
                        {items[index]}
                    </animated.span>
                    <animated.span style={{ ...springz }} key={index}>
                        {moreitems[index].split('–')[0]} –
                        <span style={{ fontSize: '1.5rem', marginLeft: '4px' }}>
                            {moreitems[index].split('–')[1]}
                        </span>
                    </animated.span>
                </h4>
            ))}
        </div>
    );
};

export default IdealDef;
