import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { animated, useSpring } from 'react-spring';
import Chart from '../components/icons/chartUseSpring';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'reactstrap';
import HeroCarousel from '../components/carousel/hero-carousel';
import FeaturedProperty from '../components/home/featured-property';
import IdealDef from '../components/home/idealdef';
import NumberBox from '../components/home/numberboxes';
import SideArrow from '../components/icons/arrows/SideArrow';
import SideArrowLeft from '../components/icons/arrows/ArrowGrayLeft';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { Waypoint } from 'react-waypoint';
// eslint-disable-next-line
import { hidden } from 'ansi-colors';
//import AnimatePhotos from "../components/home/twinphotos";

const Home = ({ data }) => {
    const { page } = data.wpgraphql;
    const { section } = page.acf_home_fields;
    const [on, toggle] = useState(false);

    const fadein = useSpring({
        opacity: on ? 1 : 0,
        from: { opacity: 0, transform: 'translate3d(0,50px,0)' },
        to: {
            opacity: on ? 1 : 0,
            transform: on ? 'translate3d(0,0,0px)' : 'translate3d(0,50px,0)',
        },
        config: { tension: 100 },
    });
    const topphoto = useSpring({
        //opacity: on ? 1 : 0,
        width: on ? '100%' : '0%',
        overflow: on ? 'hidden' : 'hidden',
        config: { tension: 100 },
    });

    // eslint-disable-next-line
    let parallax;
    return (
        <Parallax ref={(ref) => (parallax = ref)}>
            <Layout>
                <SEO
                    title={page.title}
                    description={data.wpgraphql.page.acf_page_meta.metadescription}
                />

                <article className="page type-page status-publish hentry entry">
                    <header className="section__header header--home">
                        <div className="container-fluid">
                            <Row>
                                <Col md={{ size: 12, offset: 2 }} className="px-0">
                                    {page.acf_home_fields.heroCarousel != null && (
                                        <HeroCarousel items={page.acf_home_fields.heroCarousel} />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </header>
                    <section
                        data-section="definition-section"
                        className="section__ideal-definition"
                    >
                        <Row className="no-gutters">
                            <Col
                                md={{ size: 6, offset: 0 }}
                                lg={{ size: 6, offset: 1 }}
                                className="pr-0"
                            >
                                <div className="container pr-0">
                                    <span className="header__text">
                                        <h1 className="header__heading">
                                            {/* {page.acf_home_fields.heroHead} */}
                                            Generating Wealth and Income Through Real Estate
                                            Partnerships.
                                        </h1>
                                        <div className="header__paragraph">
                                            <p className="body-copy">
                                                <span className="header__subhead">
                                                    Ideal Capital Group{' '}
                                                </span>
                                                is a private real estate investment firm
                                                specializing in acquiring and repositioning
                                                multifamily properties in the Western United States.
                                                We have a proven track record in creating and
                                                managing successful joint ventures that help our
                                                clients reach their financial goals.
                                            </p>
                                        </div>
                                    </span>
                                    {page.acf_home_fields.idealDefinitionImage != null && (
                                        <Img
                                            fixed={
                                                page.acf_home_fields.idealDefinitionImage.imageFile
                                                    .childImageSharp.fixed
                                            }
                                            className="d-none d-md-block supporting-image"
                                            alt={page.acf_home_fields.idealDefinitionImage.altText}
                                        />
                                    )}
                                </div>
                            </Col>
                            <Col
                                md={{ size: 8 }}
                                lg={{ size: 7, offset: 0 }}
                                className="column__ideal-definition"
                            >
                                <div className="column__ideal-definition">
                                    <span className="ideal-definition__text">
                                        {/* <h4 className="heading4">
                                            Here’s Why Real Estate
                                            <br />
                                            is the IDEAL Investment:
                                        </h4> */}
                                        <IdealDef />
                                        <p className="body-copy">
                                            These sources of value are so core to our business plan
                                            that they became the identity of our company.
                                        </p>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <section className="company-stats" data-component="company-stats">
                        <Container className="container-fluid">
                            <div className="copany-stats__cards row">
                                <NumberBox />
                            </div>
                        </Container>
                    </section>
                    {section.map(
                        (section, i) =>
                            i === 0 && (
                                <section
                                    className="section__callout"
                                    data-component={`home-section-callout-${i + 1}`}
                                >
                                    <ParallaxLayer
                                        offset={0.3}
                                        speed={0.1}
                                        className="parallax__arrow-container d-none d-md-block"
                                    >
                                        <SideArrowLeft className="parallax__arrow-left" />
                                    </ParallaxLayer>
                                    <Container>
                                        <Row>
                                            <Col
                                                className="callout__images "
                                                sm={{ size: 6 }}
                                                md={{ size: 5 }}
                                                lg={{ size: 4 }}
                                            >
                                                {section.backImage != null && (
                                                    <ParallaxLayer
                                                        offset={0.35}
                                                        speed={0.25}
                                                        className="d-none d-lg-block"
                                                    >
                                                        <Img
                                                            className="callout__back-image  "
                                                            fixed={
                                                                section.backImage.imageFile
                                                                    .childImageSharp.fixed
                                                            }
                                                            alt={section.backImage.altText}
                                                        />
                                                    </ParallaxLayer>
                                                )}
                                                <Waypoint
                                                    bottomOffset="12%"
                                                    onEnter={() => {
                                                        if (!on) toggle(true);
                                                    }}
                                                />
                                                <animated.div style={fadein}>
                                                    {section.frontImage != null && (
                                                        <animated.div style={topphoto}>
                                                            <Img
                                                                className="callout__front-image"
                                                                fixed={
                                                                    section.frontImage.imageFile
                                                                        .childImageSharp.fixed
                                                                }
                                                                alt={section.frontImage.altText}
                                                            />
                                                        </animated.div>
                                                    )}
                                                </animated.div>
                                            </Col>
                                            <Col
                                                className="callout__text"
                                                sm={{ size: 6, offset: 1 }}
                                            >
                                                <h3 className="heading2">{section.head}</h3>
                                                <h4 className="blockquote">{section.subhead}</h4>
                                                <p className="body-copy">{section.text}</p>
                                                <Link
                                                    className="btn btn-primary"
                                                    to={section.buttonPath}
                                                >
                                                    {section.buttonText}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            )
                    )}{' '}
                    {section.map(
                        (section, i) =>
                            i === 1 && (
                                <section
                                    className="section__callout"
                                    data-component={`home-section-callout-${i + 1}`}
                                >
                                    <div className="container">
                                        <Row>
                                            <Col
                                                className="callout__images "
                                                sm={{ size: 6, offset: 1 }}
                                                md={{ size: 6, offset: 1 }}
                                            >
                                                {section.backImage != null && (
                                                    <ParallaxLayer
                                                        offset={0.35}
                                                        speed={0.25}
                                                        className="d-none d-lg-block"
                                                    >
                                                        <Img
                                                            className="callout__back-image  "
                                                            fixed={
                                                                section.backImage.imageFile
                                                                    .childImageSharp.fixed
                                                            }
                                                            alt={section.backImage.altText}
                                                        />
                                                    </ParallaxLayer>
                                                )}
                                                <Waypoint
                                                    bottomOffset="25%"
                                                    onEnter={() => {
                                                        if (!on) toggle(true);
                                                    }}
                                                />
                                                <animated.div style={fadein}>
                                                    {section.frontImage != null && (
                                                        <Img
                                                            className="callout__front-image"
                                                            fixed={
                                                                section.frontImage.imageFile
                                                                    .childImageSharp.fixed
                                                            }
                                                            alt={section.frontImage.altText}
                                                        />
                                                    )}
                                                </animated.div>
                                            </Col>
                                            <Col className="callout__text" sm={{ size: 6 }}>
                                                <h3 className="heading2">{section.head}</h3>
                                                <h4 className="blockquote">{section.subhead}</h4>
                                                <p className="body-copy">{section.text}</p>
                                                <Link
                                                    className="btn btn-primary"
                                                    to={section.buttonPath}
                                                >
                                                    {section.buttonText}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </section>
                            )
                    )}
                    <ParallaxLayer
                        offset={-0.35}
                        speed={0.1}
                        className="parallax__arrow-container d-none d-md-block"
                    >
                        <SideArrow className="parallax__arrow-right" />
                    </ParallaxLayer>
                    <FeaturedProperty />
                    <div className="container-fluid">
                        {section.map(
                            (section, i) =>
                                i === 2 && (
                                    <section className="section__infographic">
                                        <Row>
                                            <Col className="infographic__image" sm={{ size: 7 }}>
                                                <Chart name="3DChart" />
                                            </Col>
                                            <Col className="infographic__text" sm={{ size: 6 }}>
                                                <h2 className="heading2">{section.head}</h2>

                                                <p className="body-copy">{section.text}</p>
                                                <Link
                                                    className="btn btn-primary"
                                                    to={section.buttonPath}
                                                >
                                                    {section.buttonText}
                                                </Link>
                                            </Col>
                                        </Row>
                                    </section>
                                )
                        )}
                    </div>
                    <footer className="entry-footer" />
                </article>
            </Layout>
        </Parallax>
    );
};

export default Home;

export const query = graphql`
    query {
        wpgraphql {
            page(id: "cGFnZTo1") {
                title
                acf_page_meta {
                    metadescription
                }
                acf_home_fields {
                    heroHead
                    heroText
                    heroCarousel {
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fluid(maxWidth: 1900, quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    idealDefinitionImage {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fixed(width: 585, height: 390, quality: 90) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                    numberCards {
                        head
                        subhead
                        text
                    }
                    section {
                        head
                        subhead
                        text
                        buttonText
                        buttonPath
                        backImage {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fixed(width: 637, height: 425, quality: 90) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                        frontImage {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fixed(width: 462, height: 462, quality: 90) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
