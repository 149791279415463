import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { animated, useSpring, useTrail, useChain } from 'react-spring';
import { Waypoint } from 'react-waypoint';

const NumberBox = () => {
    const data = useStaticQuery(graphql`
        query BoxQuery {
            wpgraphql {
                page(id: "cGFnZTo1") {
                    acf_home_fields {
                        numberCards {
                            head
                            subhead
                            text
                        }
                    }
                }
            }
        }
    `);

    const bottomBoxes = [
        {
            head: '30%+',
            subhead: 'AVERAGE IRR',
            text: 'We have a narrow focus on multifamily investments and have decades of experience in understanding how to drive exceptional returns to our investors.',
        },
        {
            head: '$535,000,000 ',
            subhead: 'of 1031 equity placed since 2019',
            text: 'We are experts at 1031 exchanges and specialize in creating tax-deferred passive investments for our clients.',
        },
        {
            head: '1,000+',
            subhead: 'Investors Nationwide',
            text: 'We view our investors as partners, and we will always treat our investors as such—sharing the good news when we have it and our plans to mitigate bad news when it presents itself.',
        },
    ];
    const boxes = [...data.wpgraphql.page.acf_home_fields.numberCards, ...bottomBoxes];

    const [on, toggle] = useState(false);

    const opacityTarget = 1;
    const springRef = useRef();
    const spring = useSpring({
        ref: springRef,
        from: { opacity: 0, transform: 'scale(0.9)' },
        to: { opacity: on ? opacityTarget : 0, transform: on ? 'scale(1)' : 'scale(0.9)' },
        config: { duration: 300, friction: 40 },
    });
    const springRef2 = useRef();
    const spring2 = useSpring({
        ref: springRef2,
        from: { opacity: 0, transform: 'scale(0.9)' },
        to: { opacity: on ? opacityTarget : 0, transform: on ? 'scale(1)' : 'scale(0.9)' },
        config: { duration: 300, friction: 40 },
    });
    const springRef3 = useRef();
    const spring3 = useSpring({
        ref: springRef3,
        from: { opacity: 0, transform: 'scale(0.9)' },
        to: { opacity: on ? opacityTarget : 0, transform: on ? 'scale(1)' : 'scale(0.9)' },
        config: { duration: 300, friction: 40 },
    });

    const springRef4 = useRef();
    const springRef5 = useRef();
    const springRef6 = useRef();

    const trailRef = useRef();
    const trail = useTrail(boxes.length, {
        ref: trailRef,
        from: { opacity: 0, transform: 'translate3d(0,10px,0)' },
        to: {
            opacity: on ? 1 : 0,
            transform: on ? 'translate3d(0,0,0px)' : 'translate3d(0,10px,0)',
        },
        config: { friction: 25 },
    });

    useChain(
        on
            ? [trailRef, springRef, springRef2, springRef3, springRef4, springRef5, springRef6]
            : [springRef6, springRef5, springRef4, springRef3, springRef2, springRef, trailRef],
        [0.5, on ? 1 : 5, 1.1, 1.2]
    );

    // This will orchestrate the two animations above, comment the last arg and it creates a sequence
    //  useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.6])

    return (
        <>
            <Waypoint
                bottomOffset="12%"
                onEnter={() => {
                    if (!on) toggle(true);
                }}
            />
            {trail.map(({ ...animation }, index) => {
                const hasContent = boxes[index].head || boxes[index].subhead || boxes[index].text;
                return (
                    <animated.div
                        style={{ ...animation, order: index }}
                        key={index}
                        className={`col-sm-14 col-md-4 copany-stats__card card0${index} ${
                            hasContent ? '' : 'image-card'
                        }`}
                    >
                        {hasContent ? (
                            <>
                                <animated.h3 style={{ ...spring }} className="heading3--bold">
                                    {boxes[index].head}
                                </animated.h3>
                                <animated.h4 style={{ ...spring2 }} className="heading4">
                                    {boxes[index].subhead}
                                </animated.h4>
                                <animated.p style={{ ...spring3 }} className="body-copy">
                                    {boxes[index].text}
                                </animated.p>
                            </>
                        ) : (
                            <img
                                src="/images/Ideal_calloutbox_graphic.svg"
                                alt=""
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: 0.7,
                                }}
                            />
                        )}
                    </animated.div>
                );
            })}
        </>
    );
};

export default NumberBox;
